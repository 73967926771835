// openers of mobile and tablet menus + filter opener/loser
(function(){
    $('[data-open-menu]').click(function(){
        $(this).toggleClass('open');
        $('[data-menu]').toggleClass('open');
        $('[data-menu-holder]').toggleClass('open');
        $('[data-menu-search]').toggleClass('open');
    });

    $('[data-show-search]').on('focus blur', function(){
        $(this).parents('form').toggleClass('show');
    });

    $('[data-filter-closer]').click(function(){
        var form = $(this).parents('form');
        form.addClass('closed');
    });

    $('[data-filter-opener]').click(function(){
        var form = $(this).parents('form');
        form.removeClass('closed');
    });

    $('[data-page-opener]').click(function(){
        var pageHeader = $(this).parents('.page-header');
        pageHeader.toggleClass('open');
    });

})();

// 1024 menu helper - right positioning of parent
(function(){
    var mainMenu = $('[data-menu]');
    function updateMenuPositions() {
        var menuRight = mainMenu.offset().left + mainMenu.width();
        $('.main-menu__child').each(function(){
            var self = $(this);
            self.css('left', 0);
            var parent = self.parents('.main-menu__item_root');
            var selfLeft = self.offset().left;
            if(selfLeft + self.width() > menuRight) {
                var right = self.offset().left + self.outerWidth();
                self.css('left', menuRight - right);
            }
        });
    }
    updateMenuPositions();
    $(window).on('resize orientationchange', updateMenuPositions);
})();


// feature menu
(function(){
    var featureMenu = $('.feature__menu');
    featureMenu.click(function(){
        $(this).toggleClass('feature__menu_open');
    });
    $(document).click(function(e){
        var t = $(e.target);
        if(!t.is(featureMenu) && !$(featureMenu).has(t).length) {
            featureMenu.removeClass('feature__menu_open');
        }
    });
    $(document).on('click', '.feature__tab', function(){
        var tab = $(this);
        if(window.AOS) {
            setTimeout(function(){
                AOS.refreshHard();
            }, 10);
        }
        if(tab.hasClass('feature__tab_container')) return;

        $('.feature__tab').removeClass('active');
        tab.addClass('active');
        var feature = $('[data-feature]');
        feature.each(function(){
            var f = $(this);
            if(f.data('feature') === tab.data('feature-tab')) {
                f.addClass('visible');
            } else {
                f.removeClass('visible');
            }
        });
    });

    function detectOutsideTab() {
        var indices = [], lastIndex = -1, moved = $();
        var wrapper = $('.feature__wrapper');
        wrapper.children('.feature__tab_container').remove();
        var featureTab = wrapper.children('.feature__tab');
        var revertClasses = [];
        featureTab.each(function(index){
            var tab = $(this);
            if(tab.hasClass('hidden')) {
                tab.removeClass('hidden');
                revertClasses.push(index);
            }
            if (wrapper.width() < tab.position().left + tab.outerWidth(true)) {
                if (lastIndex === -1)
                    lastIndex = index - 1;
                indices.push(index);
            }
        });
        for(var r = 0; r < revertClasses.length; r++) {
            featureTab.eq(revertClasses[r]).addClass('hidden')
        }
        for(var i =0; i < indices.length; i++) {
            var t = featureTab.eq(indices[i]);
            moved = moved.add(t.clone(true));
            t.addClass('hidden');
        }
        featureTab.each(function(index){
            if(indices.indexOf(index) === -1) {
                $(this).removeClass('hidden');
            }
        });
        if(moved.length) {
            moved.removeClass('hidden');
            var containerTab = $('<div class="feature__tab feature__tab_container"/>').html('...'),
                lastItem = featureTab.eq(lastIndex),
                parent = lastItem.parent(),
                list = $('<ul class="feature__container"/>')
            ;
            containerTab.append(list);
            var width = parent.width() - (lastItem.get(0).offsetLeft + lastItem.outerWidth(true));
            containerTab.width(width);
            moved.each(function(){
                list.append($(this).clone(true));
            });
            containerTab.insertAfter(lastItem);
        }
    }
    $(document).on('click', function(e){
        var tabContainer = $('.feature__tab_container');
        var t = $(e.target);
        var container = tabContainer.children('.feature__container');
        if((!t.is(tabContainer) && !$(tabContainer).has(t).length) || t.parents(tabContainer).length && container.hasClass('open')) {
            container.removeClass('open');
        } else {
            container.addClass('open');
        }
    });

    detectOutsideTab();
    $(window).on('resize orientationchange', detectOutsideTab);

})();

// add rows to configurator
(function(){
    $('[data-multi-add]').each(function(){
        var button = $(this);
        var item = button.data('multi-add');
        var block = $('[data-multi-item="'+item+'"]').clone();
        var nameIndex = block.data('multi-index');

        button.click(function(){
            var blocks = $('[data-multi-item="'+item+'"]');
            var lastBlock = blocks.eq(blocks.length - 1);

            var inputs = block.find('[name]');
            inputs.each(function(){
                console.log($(this).attr('name').match(/\[[^\]]+\]/g))
            });
        });

    });
})();


/*(function(){

    $('[data-send-form]').each(function(){
        var form = $(this);
        var maskOptions = {
            mask: '+{7} (000) 000-00-00'
        };
        new IMask(form.find('[name="phone"]')[0], maskOptions);
        var button = form.find('[type="submit"]');
        var elems = form.find('[name]');
        elems.focus(function(){
            $(this).removeClass('popup-form__error');
        });
        button.click(function(e){
            e.preventDefault();
            var error = false;
            elems.each(function(){
                var elem = $(this);
                var value = elem.val();
                switch(elem.attr('name')) {
                    case 'company':
                    case 'phone':
                    case 'comment':
                        break;
                    case 'email':
                        if(!value || !value.match(/@/)) {
                            elem.addClass('popup-form__error');
                            error = true;
                        }
                        break;
                    default:
                        if(!value) {
                            elem.addClass('popup-form__error');
                            error = true;
                        }
                }
            });
            if(!error) {
                var data = form.serializeArray();
                $.ajax({
                    data: data,
                    url: form.attr('action'),
                    method: form.attr('method'),
                    success: function() {
                        alert('Спасибо! В скором времени наши менеджеры свяжутся с Вами');
                        Popup.close();
                    }
                });
            }
        });
    });
})();*/



